import React from 'react'
import { Stack, Main } from '@layout'
import { Text } from 'theme-ui'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Section from '@components/Section'

export default () => (
  <>
    <Seo title='Event Agreement' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Điều khoản tham gia"
          subheader='Khi đồng ý đăng tham gia các hoạt động của Outdoors nghĩa là bạn đã đồng ý với cả thoả thuận, điều khoản dưới đây.'
        />
        <Divider />
        <Section>
          <Text variant='h2'>
            TRÁCH NHIỆM
          </Text>
          <Text variant='p'>
            Các thành viên tham gia các hoạt động của Outdoors chịu trách nhiệm hoàn toàn với:
            <ul>
              <li>Sự an toàn của bản thân bao gồm sức khoẻ, thân thể, tính mạng.</li>
              <li>Các tài sản, tiền bạc, đồ dùng, vật dụng cá nhân.</li>
              <li>Với các hoạt động ngoài trời như leo núi, cắm trại:</li>
              <ul>
                <li>Có trách nhiệm tự chuẩn bị trang phục chuyên dụng leo núi, tối thiểu là giày (đối với leo núi).</li>
                <li>Có trách nhiệm, tinh thần tự luyện tập thể lực, tối thiểu là trong 1 tuần trước khi tham gia các hoạt động.</li>
              </ul>
            </ul>
            <strong>Outdoors</strong> có trách nhiệm chuẩn bị thông tin về cung đường, các thông tin liên lạc trong trường hợp khẩn cấp xảy ra, quan sát, hướng dẫn các thành viên trong các hoạt động của cộng đồng.
          </Text>
          <Text variant='h2'>
            LỆ PHÍ
          </Text>
          <Text variant='p'>
            Khi tham gia các hoạt động của Outdoors, các loại phí được coi là lệ phí đăng ký sẽ không hoàn lại trong mọi trường hợp.
            Với các lệ phí, chi phí được coi là đặt trước (đặt phòng, đặt vé) sẽ được refund toàn phần, hoặc một phần theo các trường hợp sau:
            <ul>
              <li>Liên lạc trước 1 tuần + thảo luận cùng Outdoors + tìm được người thay thế -{">"} <strong>hoàn trả 100%.</strong></li>
              <li>Liên lạc trong 1 tuần + thảo luận cùng Outdoors + tìm được người thay thế -{">"} <strong>hoàn trả 50%.</strong></li>
              <li>Liên lạc trước hoặc trong 1 tuần nhưng không tìm được người thay thế -{">"} <strong>không hoàn trả.</strong></li>
            </ul>
          </Text>
          <Text variant='h2'>
            NỘI DUNG, HÌNH ẢNH
          </Text>
          <Text variant='p'>
          Các nội dung, hình ảnh được ghi lại trong chuyến đi có thể được Outdoors sử dụng với mục đích tạo các content, nội dung tại các trang của Outdoors.
          Trong trường hợp bạn muốn gỡ hình ảnh, nội dung của bản thân. Xin vui lòng liên hệ tới hòm thư của Outdoors.
          </Text>
          <Text variant='h2'>
            QUY TẮC ỨNG XỬ
          </Text>
          <Text variant='p'>
            Outdoors luôn mong muốn xây dựng một cộng đồng lành mạnh, vui vẻ và văn minh. Chính vì thế, trong suốt quá trình tham gia các hoạt động, các thành viên xin vui lòng tuân thủ các quy tắc ứng xử sau:
            <ul>
            <li>Giữ gìn vệ sinh chung, không xả rác bừa bãi ra môi trường, không làm bẩn các cơ sổ thiết bị công cộng, rác cá nhân phải tự mang về nhà hoặc vứt tại nơi quy định.</li>
            <li>Không nói tục, chửi bậy, gây gổ đánh nhau.</li>
            <li>Không gây mất trật tự, ồn ào, ảnh hưởng tới những người xung quanh.</li>
            <li>Không chủ quan, không tự ý tách đoàn hoặc có những hành động gây nguy hiểm tới bản thân và những thành viên còn lại.</li>
            <li>Trang phục phù hợp, không phản cảm.</li>
            <li>Khi tham gia các hoạt động leo núi:</li>
              <ul>
                <li>Trên đường đi luôn ưu tiên người đang đi lên.</li>
                <li>Không đốt lửa và dựng lều khi chưa được cho phép.</li>
                <li>Tuân thủ các quy định, nội quy của từng khu vực hoạt động diễn ra.</li>
              </ul>
            </ul>
          </Text>
        </Section>
      </Main>
    </Stack>
  </>
)
